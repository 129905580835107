/** @format */

@import "~antd/lib/style/themes/dark.less";
@import "~antd/dist/antd.less";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 12vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva,
    Verdana, sans-serif;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Istructions-box {
  padding: 10px;
  margin: 20px;
  background-color: #13151a;
  border-radius: 5px;
  width: 60%;
  text-align: left;
}

.Istructions-title {
  color: #04bed6;
}
.Istructions-salute {
  color: #04a735;
}

.Instructions-code {
  background-color: #282c34;
  padding: 2px;
}

.Istructions-box p,
i {
  font-size: 14px;
}

.App-link {
  color: #4094ac;
}

.Welcome-title {
  color: white;
}

.Rstore-name {
  color: #61dafb;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #000 !important;
}

.ant-menu-dark .ant-menu-item-selected > a {
  color: black !important;
}

.ant-menu-dark .ant-menu-item-active > a {
  color: black !important;
}
::selection {
  background-color: #282c34;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-family: 'Lato', sans-serif;@primary-color: #FFFF;@btn-default-color: #000;@btn-default-bg: #FFFF;@item-active-bg: #000;